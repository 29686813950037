<template>
  <div id="nuevomensaje">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t('mensajeria.nuevo_mensaje') }}</v-toolbar-title>
        <v-spacer />
        <v-btn data-test="patient-edit-button-close-message" icon @click="cancelarNuevoMensaje">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-0">
        <v-autocomplete
          data-test="patient-edit-select-destinatario"
          v-model="nuevomensaje.destinatario"
          item-value="value"
          item-text="label"
          :items="pacientes"
          :label="$t('mensajeria.select_user')"
          outlined
          clearable
          filled
          dense
        />

        <v-text-field data-test="patient-edit-input-subject" v-model="nuevomensaje.motivo" outlined filled dense :placeholder="$t('mensajeria.subject')" />

        <v-autocomplete
          data-test="patient-edit-select-specialty"
          v-show="specialties.length > 1"
          v-model="nuevomensaje.specialtySelected"
          class="mb-6"
          hide-details
          item-text="name"
          item-value="id"
          persistent-hint
          outlined
          clearable
          filled
          dense
          :label="$t('agenda.especialidad')"
          :items="specialties"
        />

        <v-textarea
          data-test="patient-edit-input-message"
          v-model="nuevomensaje.mensaje"
          outlined
          filled
          :placeholder="$t('mensajeria.mensaje')"
          :rows="3"
          hide-details=""
        />
      </v-card-text>

      <v-toolbar flat>
        <v-btn data-test="patient-edit-button-attach" text color="primary" @click="adjuntarFile('nuevo')">
          <v-icon class="mr-2">mdi-attachment</v-icon>
          <template v-if="!nuevomensaje.adjuntado">{{ $t('mensajeria.attach_file') }}</template>
        </v-btn>
        <input
          id="filenew"
          ref="filenew"
          type="file"
          accept="application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, .pdf,
           video/mp4, video/x-m4v, video/avi, .pptx, .ppt"
          style="display: none"
          @change="onFileChangeNuevo"
        />
        <v-chip v-if="nuevomensaje.adjuntado" small label close @click:close="removeFile">
          {{ nuevomensaje.file.name }}
        </v-chip>

        <v-spacer />

        <v-btn
          data-test="patient-edit-button-send-message"
          depressed
          color="primary"
          :loading="isSending"
          :disabled="disabledButton || nuevomensaje.mensaje === ''"
          @click="enviarMensaje"
          >{{ $t('mensajeria.enviar') }}</v-btn
        >
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>
import Alerts from '@/mixins/Alerts';
import { sendMensajeUrl, getHeaderMultimedia } from '../../../config/config';

export default {
  name: 'Nuevomensaje',
  mixins: [Alerts],
  props: {
    pacientes: {
      type: Array,
      default: () => [],
    },
    pack: {
      type: Object,
      default: () => [],
    },
    specialties: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      nuevomensaje: {
        motivo: '',
        destinatario: '',
        mensaje: '',
        file: '',
        adjuntado: false,
        specialtySelected: 0,
      },
      isSending: false,
      color_enviar: 'blue',
      disabledButton: false,
      timeout: 5000,
      mode: '',
    };
  },
  watch: {
    pack(p) {
      if (typeof p.id !== 'undefined' && p.id > 0) {
        this.nuevomensaje.motivo =
          p.nombrePack + ' ' + this.$t('packs.sesion') + ': ' + (p.sesiones_total - p.sesiones_pendientes + 1);
      }
    },
  },
  created() {
    // this.nuevomensaje.destinatario.label = this.pacientes[0].label;
    // this.nuevomensaje.destinatario.value = this.pacientes[0].value;
  },
  methods: {
    async removeFile() {
      const res = await this.$dialog.confirm({
        text: 'Do you really want to exit?',
        title: this.$t('common.confirm'),
        actions: [
          {
            text: this.$t('common.no'),
            key: false,
          },
          {
            text: this.$t('common.yes'),
            color: 'error',
            key: true,
          },
        ],
      });
      if (res) {
        this.nuevomensaje.file = null;
        this.nuevomensaje.adjuntado = false;
      }
    },
    onFileChangeNuevo() {
      this.nuevomensaje.adjuntado = true;
      this.nuevomensaje.file = this.$refs.filenew.files[0];
    },
    quitarDestinatario() {
      this.nuevomensaje.destinatario = '';
    },
    adjuntarFile(tipo) {
      if (tipo === 'nuevo') {
        this.$refs.filenew.click();
      } else {
        this.$refs.file.click();
      }
    },
    cancelarNuevoMensaje() {
      this.resetNuevoMensaje();
      if (typeof this.$route.params.nuevo !== 'undefined' && this.$route.params.nuevo === 'nuevo') {
        this.$router.go(-1);
      } else {
        this.$emit('clickedVolver', true);
      }
    },
    resetNuevoMensaje() {
      this.nuevomensaje.adjuntado = false;
      this.nuevomensaje.destinatario = '';
      this.nuevomensaje.motivo = '';
      this.nuevomensaje.mensaje = '';
    },
    enviarMensaje() {
      this.nuevomensaje.destinatario =
        this.pacientes.length === 1 ? this.pacientes[0].value : this.nuevomensaje.destinatario;
      if (this.nuevomensaje.mensaje.length < 2) {
        return this.toastError(this.$t('mensajeria.add_texto_al_mensaje'));
      } else if (this.nuevomensaje.destinatario === '' || this.nuevomensaje.destinatario === null) {
        return this.toastError(this.$t('mensajeria.add_destinatario_al_mensaje'));
      }

      if (!this.disabledButton) {
        this.isSending = true;
        this.dialog = false;
        this.color_enviar = 'blue lighten-3';
        this.disabledButton = true;
        const formData = new FormData();
        formData.append('file', this.nuevomensaje.file);
        formData.append('texto', this.nuevomensaje.mensaje);
        formData.append('motivo', this.nuevomensaje.motivo);
        formData.append(
          'specialtyIdSelected',
          typeof this.nuevomensaje.specialtySelected === 'undefined' ? 0 : this.nuevomensaje.specialtySelected
        );
        formData.append('mensajeria_id', 0);

        const destId = this.nuevomensaje.destinatario;
        const destName =
          this.pacientes.length === 1 ? this.pacientes[0].label : this.pacientes.find(item => item.value === destId);

        formData.append('to', destId);
        formData.append('to_name', destName);

        //formData.append("to_name", this.nuevomensaje.destinatario.label);
        formData.append('tiene_adjunto', this.nuevomensaje.adjuntado);
        formData.append('id', this.authUser.id);
        if (typeof this.pack.id !== 'undefined' && this.pack.id > 0) {
          formData.append('pack_id', this.pack.id);
        }
        this.$http
          .post(sendMensajeUrl, formData, { headers: getHeaderMultimedia() })
          .then(response => {
            if (response.status === 200 && (response.data.estado === '1' || response.data.estado === '2')) {
              this.resetNuevoMensaje();
              this.$emit('clickedNuevo', response.data);
            } else {
              this.toastError(this.$t('mensajeria.error_enviando'));
            }
            this.disabledButton = false;
          })
          .catch(err => {
            this.$log.error(err);
          })
          .finally(() => {
            this.isSending = false;
          });
      }
    },
  },
};
</script>
