<template>
  <div id="packscontratados">
    <div v-if="loading" class="loading_pack">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>
    <v-flex v-else class="mail-content white ">
      <v-tabs v-model="tab" fixed-tabs grow class="tabs_mail">
        <v-tab>{{ $t('videoconsulta.pendientes') }}</v-tab>
        <v-tab>{{ $t('packs.completados') }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <!-- pendientes -->
        <v-tab-item key="0">
          <div v-if="no_pendientes" class="text-center grey--text pa-4">
            {{ $t('packs.sin_pendientes') }}
          </div>
          <div v-for="(item, index) in packs.pendientes" :key="item.title" class="item_pack_div">
            <v-layout wrap>
              <v-flex xs7>
                <h5>
                  <strong>{{ item.fullName }} - {{ item.nombrePack }}</strong>
                </h5>
              </v-flex>
              <v-flex xs5>
                <span class="fecha_video">{{ $t('packs.fecha_contratado') }}: {{ item.fecha }}</span>
              </v-flex>
              <v-flex xs10 class="motivo_pasck">
                <span
                  >{{
                    item.motivo_corto !== '' ? item.motivo_corto : $t('videoconsulta.singular') + ' ' + item.tipo.nombre
                  }}
                  <v-chip label color="green" text-color="white" class="mr-1">
                    <v-icon left>mdi-label</v-icon>{{ item.importe }} {{ moneda }}
                  </v-chip>
                  <v-chip label color="teal darken-1" text-color="white" class="mr-1">
                    {{ $t('packs.sesiones_totales') }}: {{ item.sesiones_total }}
                  </v-chip>
                  <v-chip label color="cyan darken-1" text-color="white" class="mr-1">
                    {{ $t('packs.sesiones_restantes') }}: {{ item.sesiones_pendientes }}
                  </v-chip>
                </span>
              </v-flex>
              <v-flex xs2 class="text-right botones_video">
                <v-menu offset-y top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon dark color="primary" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                  </template>
                  <v-list>
                    <v-list-item color="blue darken-4" @click="nuevaCitaPack(item, index, 1)">
                      <v-list-item-title
                        ><v-icon left color="blue darken-4">mdi-camera</v-icon
                        >{{ $t('videoconsulta.nueva_video') }}</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item color="blue darken-4" @click="nuevaCitaPack(item, index, 2)">
                      <v-list-item-title
                        ><v-icon left color="blue darken-4">mdi-email</v-icon
                        >{{ $t('mensajeria.nuevo_mensaje') }}</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item color="blue darken-4" @click="nuevaCitaPack(item, index, 3)">
                      <v-list-item-title
                        ><v-icon left color="blue darken-4">mdi-check-all</v-icon
                        >{{ $t('packs.marcar_como_completado') }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-divider />
          </div>
        </v-tab-item>
        <!-- completados -->
        <v-tab-item key="1">
          <v-alert
            v-if="no_completados"
            :color="custom_template ? 'primary' : 'light-blue'"
            icon="mdi-label-off"
            :value="true"
          >
            {{ $t('packs.sin_completados') }}
          </v-alert>
          <div v-for="item in packs.completados" :key="item.title" class="item_pack_div">
            <v-layout wrap>
              <v-flex xs7>
                <h5>
                  <strong>{{ item.fullName }} - {{ item.nombrePack }}</strong>
                </h5>
              </v-flex>
              <v-flex xs5>
                <span class="fecha_video">{{ $t('packs.fecha_contratado') }}: {{ item.fecha }}</span>
              </v-flex>
              <v-flex xs12 class="motivo_pasck">
                {{ item.descripcionPack }}<br />
                <span
                  >{{
                    item.motivo_corto !== '' ? item.motivo_corto : $t('videoconsulta.singular') + ' ' + item.tipo.nombre
                  }}
                  <v-chip label color="green" text-color="white">
                    <v-icon left>mdi-label</v-icon>{{ item.importe }} {{ moneda }}
                  </v-chip>
                  <v-chip label color="teal darken-1" text-color="white">
                    {{ $t('packs.sesiones_totales') }}: {{ item.sesiones_total }}
                  </v-chip>
                </span>
              </v-flex>
            </v-layout>
            <v-divider />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-flex>

    <span slot="widget-content">
      <v-dialog v-model="dialog_confirm.dialog" dialog_delete max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('packs.marcar_como_completado') }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <p>{{ $t('packs.marcar_como_completado_texto') }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="dialog_confirm.dialog = false"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn
              color="green darken-1"
              text
              @click.native="
                dialog_confirm.dialog = false;
                completarPack();
              "
              ><v-icon>mdi-check-all</v-icon> {{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>
    <!-- Modal Nuevo Servicio Videoconsulta -->
    <v-dialog v-model="dialog_new_video" max-width="640px" scrollable>
      <nuevavideo
        :pacientes="pack_edit.pack.paciente"
        :tipos="tiposvideo"
        :pack="selected.pack"
        :profesionales="profesionales"
        :specialties="especialidades"
        @clickedVolver="dialog_new_video = false"
        @clickedNuevo="onNuevoChild"
        @reloadvideos="appointmentCreated"
      />
    </v-dialog>
    <!-- Modal Nuevo Servicio Mensajeria -->
    <v-dialog v-model="dialog_nuevomensaje" max-width="640px" scrollable>
      <nuevomensaje
        :pacientes="pack_edit.pack.paciente"
        :pack="selected.pack"
        @clickedVolver="dialog_nuevomensaje = false"
        @clickedNuevo="onNuevoChild"
      />
    </v-dialog>
    <!-- Snackbar para mostrar avisos -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </div>
</template>

<script>
import Nuevavideo from '@/components/ehealth/videoconsulta/Nueva.vue';
import Nuevomensaje from '@/components/ehealth/mensajeria/Nuevo.vue';
import { nameEnv, packsContratadosUrl, completarPackUrl, getHeader } from '@/config/config';

export default {
  components: {
    Nuevavideo,
    Nuevomensaje,
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      dialog: false,
      tab: 0,
      loading: true,
      packs: [],
      no_pendientes: false,
      no_completados: false,
      snackbar: false,
      tiposvideo: [],
      dialog_confirm: {
        name: '',
        dialog: false,
      },
      dialog_new_video: false,
      dialog_nuevomensaje: false,
      timeout: 5000,
      mode: '',
      alerta: {
        texto: '',
        icono: '',
        color: 'info',
      },
      pack_edit: {
        indice: 0,
        pack: {
          paciente: [],
          id: 0,
        },
        pack_id: 0,
      },
      selected: {
        estado: '',
        pack: {},
        indice: 0,
      },
      profesionales: [],
      especialidades: [],
      custom_template:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== '',
      es_dingdoc: nameEnv === 'dingdoc',
      moneda: nameEnv === 'dingdoc' ? '₡' : '€',
    };
  },
  computed: {},
  mounted() {
    this.loadHiredPacks();
  },
  methods: {
    loadHiredPacks() {
      this.$http.post(packsContratadosUrl, { id: this.authUser.id }, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.data) {
          this.packs = response.data.packs;
          this.tiposvideo = response.data.tipos;
          this.profesionales = response.data.profesionales;
          this.especialidades = response.data.especialidades;
          this.no_pendientes = this.packs.pendientes.length === 0;
          this.no_completados = this.packs.completados.length === 0;
        }
        this.loading = false;
      });
    },
    nuevaCitaPack(pack, indice, tipo) {
      this.selected.pack = pack;
      this.selected.indice = indice;
      this.pack_edit.pack = pack;
      this.pack_edit.indice = indice;
      if (tipo === 1) {
        // nueva videoconsulta
        this.selected.estado = '1';
        this.dialog_new_video = true;
      } else if (tipo === 2) {
        // nuevo mensaje
        this.selected.estado = '2';
        this.dialog_nuevomensaje = true;
      } else {
        // marcar como completado
        this.dialog_confirm.dialog = true;
      }
    },
    appointmentCreated() {
      this.dialog_new_video = false;
      this.loadHiredPacks();
    },
    completarPack() {
      this.$http
        .post(
          completarPackUrl,
          { user_id: this.authUser.id, pack_id: this.pack_edit.pack.id },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.packs.completados.push(this.pack_edit.pack);
            this.packs.pendientes.splice(this.pack_edit.indice, 1);
            this.no_pendientes = this.packs.pendientes.length === 0;
            this.no_completados = this.packs.completados.length === 0;
            this.alerta.texto = this.$t('packs.success_complete');
            this.alerta.color = 'success';
            this.alerta.icono = 'mdi-check-all';
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
          }
          this.snackbar = true;
        });
    },
    onNuevoChild(respuesta) {
      if (respuesta.estado === '1') {
        this.packs.pendientes[this.selected.indice].sesiones_pendientes =
          this.packs.pendientes[this.selected.indice].sesiones_pendientes - 1;
        if (this.packs.pendientes[this.selected.indice].sesiones_pendientes === 0) {
          this.packs.completados.push(this.selected.pack);
          this.packs.pendientes.splice(this.selected.indice, 1);
        }
        this.alerta.texto =
          this.selected.estado === '2'
            ? this.$t('mensajeria.enviado_correctamente')
            : this.$t('videoconsulta.enviado_correctamente');
        this.alerta.color = 'success';
        this.alerta.icono = this.selected.estado === '2' ? 'email' : 'videocam';
      } else {
        this.alerta.texto = this.$t('errors.try_again');
        this.alerta.color = 'error';
        this.alerta.icono = 'mdi-alert-circle';
      }
      this.snackbar = true;
      this.dialog_new_video = false;
      this.dialog_nuevomensaje = false;
      this.no_pendientes = this.packs.pendientes.length === 0;
      this.no_completados = this.packs.completados.length === 0;
    },
  },
};
</script>

<style>
.loading_pack {
  margin-top: 10px;
  text-align: center;
}
.item_pack_div {
  margin: 10px;
}
v-tab {
  text-transform: uppercase;
}
</style>
